@import '~arduino-sass/src/breakpoints';
@import '../../../styles/variables';

.hero-banner {
  background-color: transparent;
  display: flex;
  align-items: center;
  margin: 0 0 100px;
  font-family: $font-roboto;
  color: $black;

  @include breakpoint($breakpoint-lg) {
    background-position: 0 0;
    margin-bottom: 50px;
  }

  &__content {
    max-width: 670px;

    @include breakpoint($breakpoint-lg) {
      max-width: 500px;
    }

    @include breakpoint($breakpoint-md) {
      width: 100%;
      max-width: none;
    }
  }

  &__box {
    margin: 0 80px;

    @include breakpoint($breakpoint-lg) {
      margin: 0 40px;
    }

    @include breakpoint($breakpoint-md) {
      height: 100%;
    }

    @include breakpoint($breakpoint-xs) {
      margin: 0 15px;
    }
  }

  &__tag {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 2.5px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid $black;

    @include breakpoint($breakpoint-md) {
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 2.5px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 2px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 36px;
    line-height: 42px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include breakpoint($breakpoint-md) {
      font-size: 36px;
      line-height: 42px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 1.5px;
    }
  }

  &__description {
    margin-bottom: 30px;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;

    @include breakpoint($breakpoint-md) {
      font-size: 16px;
      line-height: 22px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__note {
    font-family: $font-sans;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.3px;

    @include breakpoint($breakpoint-md) {
      font-size: 12px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 10px;
      line-height: 14px;
    }
  }
}

.content-page {
  // Transitions
  $transition-duration: 0.2s;

  padding: 70px 0;
  color: $black;

  .main-container {
    max-width: 980px;

    @include breakpoint($breakpoint-lg) {
      padding: 0 55px;
    }

    @include breakpoint($breakpoint-xs) {
      padding: 0 15px;
    }
  }

  @include breakpoint($breakpoint-md) {
    padding: 50px 0;
  }

  @include breakpoint($breakpoint-sm) {
    padding: 30px 0;
  }

  &.hero-banner {
    margin: 0;

    @include breakpoint($breakpoint-md) {
      padding-top: 50px;
    }

    @include breakpoint($breakpoint-xs) {
      padding-top: 20px;
    }
  }

  %standard_button {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 1.14429px;
    color: $white;
    background: $teal2;
    border-radius: 21.5px;
    padding: 8px 25px;
    text-align: center;

    @include breakpoint($breakpoint-lg) {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }

  &__content {
    display: flex;
    align-items: center;

    .image-exist &,
    .video-exist & {
      margin-bottom: 45px;
    }

    .right-side & {
      flex-direction: row-reverse;
    }

    @include breakpoint($breakpoint-lg) {
      flex-direction: column;

      .right-side & {
        flex-direction: column;
      }

      .text-on-top & {
        flex-direction: column-reverse;
      }
    }
  }

  &__image {
    width: 50%;
    padding: 0 30px;

    > img {
      width: 100%;
    }

    .left-side & {
      padding-left: 0;
    }

    .right-side & {
      padding-right: 0;
    }

    @include breakpoint($breakpoint-lg) {
      padding: 0;
      width: 100%;
      display: block;
      margin-bottom: 25px;

      > img {
        margin: 0 auto;
      }
    }
  }

  &__video {
    width: 50%;
    padding: 0 30px;

    > div {
      width: auto;
      max-height: 260px;
    }

    @include breakpoint($breakpoint-lg) {
      width: 100%;
      margin-bottom: 25px;
      padding: 0;

      > div {
        margin: 0 auto;
        max-height: 380px;
      }

      .text-on-top & {
        margin-bottom: 0;
      }
    }

    @include breakpoint($breakpoint-xs) {
      > div {
        max-height: 280px;
      }
    }
  }

  &__box {
    width: 100%;
    margin: 35px 0;
    display: flex;
    justify-content: center;

    .instagram-content & {
      margin: 20px 0 36px;
    }
  }

  &__text-box {
    max-width: 980px;
    margin: 0 auto;
    width: 50%;

    .image-not-exist &,
    .video-not-exist &,
    .description & {
      text-align: center;
      width: 100%;
    }

    @include breakpoint($breakpoint-lg) {
      width: 100%;
      text-align: center;

      .text-on-top & {
        margin-bottom: 50px;
      }
    }

    @include breakpoint($breakpoint-xs) {
      padding: 0 10px;
    }
  }

  &__tag {
    font-family: $font-roboto;

    .instagram-content & {
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      text-align: center;
      letter-spacing: 2.5px;
      text-transform: uppercase;
      color: $black;
      margin-bottom: 15px;
    }
  }

  &__title {
    font-family: $font-roboto;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    letter-spacing: 2px;
    margin-bottom: 25px;
    color: $gold;

    @include breakpoint($breakpoint-lg) {
      font-size: 30px;
      line-height: 35px;
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 1.5px;
      padding: 0 10px;
    }

    p {
      margin: 0;
    }

    .banner & {
      font-family: $font-sans;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0.3px;
      margin: 0;
      text-align: center;
      color: $black;

      @include breakpoint($breakpoint-lg) {
        font-size: 18px;
        line-height: 25px;
      }

      @include breakpoint($breakpoint-xs) {
        font-size: 16px;
        line-height: 22px;
        padding: 0;
      }
    }

    .videos-content &,
    .cards-content & {
      margin-bottom: 25px;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    padding: 0 30px;
    max-width: 980px;
    margin: 0 auto;

    .description-video &,
    .description-image &,
    .videos-content & {
      max-width: none;
      margin: 0;
      width: 100%;
      padding: 0;
    }

    .events-content &,
    .cards-content &,
    .guides-content &,
    .instagram-content & {
      text-align: center;
      width: 100%;
    }

    .description & {
      margin-bottom: 30px;
    }

    @include breakpoint($breakpoint-lg) {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.3px;
      width: 100%;
      text-align: center;
      max-width: none;

      .description-video &,
      .description-image &,
      .description & {
        text-align: left;
      }
    }

    @include breakpoint($breakpoint-xs) {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.3px;
      padding: 0 10px;
    }
  }

  &__text-content {
    text-align: left;
  }

  &__actions {
    margin: 25px 0;
    display: flex;
    justify-content: center;

    .image-exist &,
    .video-exist & {
      justify-content: flex-start;
    }

    .hero-banner & {
      justify-content: flex-start;
    }

    @include breakpoint($breakpoint-lg) {
      .image-exist &,
      .video-exist & {
        justify-content: center;
      }
    }

    @include breakpoint($breakpoint-xs) {
      .hero-banner & {
        margin: 15px 0;
        justify-content: center;
      }
    }
  }

  &__link-button {
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: $teal2;

    .instagram-content & {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.3px;

      &.more-posts {
        > svg {
          @include size(13px, 12px);

          margin-top: 1px;
          margin-left: 8px;
        }

        @include breakpoint($breakpoint-xxs) {
          font-size: 12px;
          line-height: 18px;
        }
      }

      @include breakpoint($breakpoint-xs) {
      }
    }

    .guides-content & {
      &:hover {
        color: $teal5;
      }
    }
  }

  &__button {
    @extend %standard_button;

    .banner & {
      margin-top: 25px;

      @include breakpoint($breakpoint-xs) {
        margin-top: 20px;
        margin-left: 0;
      }
    }

    .hero-banner & {
      &:hover {
        border-bottom: none;
      }
    }
  }

  &.two-columns-content {
    padding: 20px 50px;
    margin: 0 auto;

    @include breakpoint($breakpoint-lg) {
      max-width: 95%;
      padding: 20px 30px;
    }

    @include breakpoint($breakpoint-sm) {
      padding: 20px 15px;
    }

    .two-columns-content__title {
      text-transform: uppercase;
    }
  }

  &.cover {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 100%;
  }

  &.videos-content {
    background-color: $feather;
  }

  &.banner {
    margin: 0;
    padding: 35px 15px;
    background-color: $feather;

    .main-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      max-width: 1100px;
    }

    @include breakpoint($breakpoint-lg) {
      padding: 35px 15px;
    }

    @include breakpoint($breakpoint-xs) {
      padding: 25px 25px;
    }
  }

  &.guides-content {
    .main-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .instagram-content {
    &__row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    &__column {
      flex: 0 0 50%;
      max-width: 50%;
      height: inherit;
      width: inherit;

      &:nth-child(2n + 1) {
        padding-right: 10px;
      }

      &:nth-child(2n) {
        padding-left: 10px;
      }

      @include breakpoint($breakpoint-sm) {
        flex: 0 0 100%;
        max-width: 100%;

        &:first-child {
          margin-bottom: 20px;
        }

        &:nth-child(2n + 1) {
          padding-right: 0;
        }

        &:nth-child(2n) {
          padding-left: 0;
        }
      }
    }
  }

  &.events-content {
    background-color: $white;
  }

  // Item elements content
  .video-list {
    margin: 30px 0 0;
    min-height: 310px;

    &__control {
      @include size(100%);

      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;

      .icon {
        @include size(55px);

        color: #9e846d;
        position: absolute;
        border-radius: 50%;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
        top: 60px;

        &.left {
          right: -10px;
        }

        &.right {
          left: -10px;
        }

        @include breakpoint($breakpoint-lg) {
          top: 40px;
        }

        @include breakpoint($breakpoint-sm) {
          top: 60px;
        }
      }

      &.disable {
        color: #c4c4c4;
      }
    }

    &__content {
      width: 100%;
      padding: 0 30px;
    }

    @include breakpoint($breakpoint-lg) {
      margin: 30px 0 0;
    }

    .video-item {
      $default-height: 169px;
      $default-width: 310px;

      $default-height-lg: 130px;
      $default-width-lg: 280px;

      $default-height-sm: 160px;
      $default-width-sm: 250px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 0 10px;

      &__content {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
      }

      &__image {
        margin-bottom: 15px;
        max-height: $default-height;
        min-height: $default-height;

        > img {
          width: 100%;
          height: $default-height;
        }
      }

      &__video {
        width: 100%;
        margin-bottom: 15px;

        > div {
          margin: 0 auto;
          max-height: $default-height;
          min-height: $default-height;
        }
      }

      &__title {
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        letter-spacing: 0.3px;
        margin-bottom: 14px;

        p {
          margin: 0;
        }
      }

      &__description {
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.3px;
        color: #2c353a;

        p {
          margin: 0;
        }
      }

      @include breakpoint($breakpoint-lg) {
        &__image {
          margin: 0 0;
          margin-bottom: 15px;
          max-height: $default-height-lg;
          min-height: $default-height-lg;

          > img {
            height: $default-height-lg;
          }
        }

        &__video {
          > div {
            max-height: $default-height-lg;
            min-height: $default-height-lg;
          }
        }

        &__title {
          padding: 0 10px;
        }
      }

      @include breakpoint($breakpoint-sm) {
        &__image {
          max-height: $default-height-sm;
          min-height: $default-height-sm;

          > img {
            height: $default-height-sm;
          }
        }

        &__video {
          > div {
            max-height: $default-height-sm;
            min-height: $default-height-sm;
          }
        }
      }
    }
  }

  .guide-list {
    max-width: 1000px;
    border: 1px solid $smoke;
    border-radius: 2px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &__row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      border-bottom: 1px solid $smoke;

      &:last-child {
        border-bottom: none;
      }
    }

    .guide-item {
      height: inherit;
      width: 100%;
      min-height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $black;

      &:hover {
        text-decoration: none;
        border-bottom: none;
        cursor: pointer;
        background-color: $teal0;

        .guide-item__link {
          color: $white;
        }
      }

      &__title {
        height: 100%;
        padding: 15px 20px;
        flex-grow: 1;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.3px;
        display: flex;
        align-items: center;
        overflow-wrap: break-word;
        word-wrap: break-word;

        &:hover {
          cursor: pointer;
        }

        @include breakpoint($breakpoint-lg) {
          padding: 15px 20px;
          font-size: 16px;
          line-height: 19px;
        }

        @include breakpoint($breakpoint-xs) {
          padding: 15px;
          font-size: 16px;
          line-height: 19px;
        }
      }

      &__action {
        height: 100%;
        min-width: 80px;
        max-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-left: 1px solid $smoke;
      }

      &__link {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $teal2;

        &:hover {
          border-bottom: none;
        }

        svg,
        img {
          @include size(20px);
        }
      }
    }
  }

  .card-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__row {
      width: calc(100% + 10px);
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @include breakpoint($breakpoint-xs) {
        margin: 0;
        width: 100%;
      }
    }

    &__item {
      position: relative;
      height: inherit;
      width: 100%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      margin-bottom: 20px;
      padding: 0 10px;

      @include breakpoint($breakpoint-sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include breakpoint($breakpoint-xs) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: none;
        margin-bottom: 20px;
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .card-item {
      $default-height: 225px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      background: $white;
      border: 1px solid $smoke;
      border-radius: 3px;
      padding: 10px;

      &:hover {
        background-color: $teal0;

        .card-item__image {
          opacity: 0.18;

          &::after {
            background:
              linear-gradient(
                to top,
                $teal0 10%,
                rgba($white, 0) 80%
              );
            opacity: 1;
          }
        }

        .card-item__tag {
          color: $black;
          border-bottom-color: rgba($teal2, 0.5);
        }

        .card-item__title {
          color: $black;
        }

        .card-item__description {
          color: $black;
        }
      }

      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @include breakpoint($breakpoint-xs) {
          align-items: flex-start;
        }
      }

      &__image {
        background-size: cover;
        border-radius: 4px;
        display: block;
        height: 0;
        min-height: $default-height;
        max-height: $default-height;
        opacity: 1;
        position: relative;
        transition-duration: $transition-duration;
        transition-property: opacity;
        width: 100%;
        margin-bottom: 35px;

        &::after {
          @include position(absolute, 0 0 0 0);

          content: '';
          opacity: 0;
          transition-duration: $transition-duration;
          transition-property: opacity;
        }

        > img {
          width: 100%;
          border-radius: 3px;
          height: $default-height;
        }
      }

      &__text-content {
        padding: 0 15px;
      }

      &__tag {
        width: 100%;
        padding: 5px 0 10px;
        font-family: $font-roboto;
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        color: $black;
        border-bottom: 1px solid $smoke;
        margin-bottom: 15px;

        @include breakpoint($breakpoint-xs) {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 2px;
        }
      }

      &__title {
        width: 100%;
        font-family: $font-roboto;
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $gold;
        margin-bottom: 25px;
        transition-duration: $transition-duration;
        transition-property: color;

        > p {
          margin: 0;
        }

        @include breakpoint($breakpoint-xs) {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 1.5px;
        }
      }

      &__description {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.3px;
        color: $black;
        margin-bottom: 15px;
        transition-duration: $transition-duration;
        transition-property: color;

        @include breakpoint($breakpoint-xs) {
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.3px;
        }
      }
    }
  }

  .instagram-posts {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      flex: 0 0 50%;
      max-width: 50%;
      height: inherit;
      width: inherit;
      padding-bottom: 20px;

      &:nth-child(2n + 1) {
        padding-right: 10px;
      }

      &:nth-child(2n) {
        padding-left: 10px;
      }

      &:nth-last-child(-n + 2) {
        padding-bottom: 0;
      }

      @include breakpoint($breakpoint-sm) {
        padding-bottom: 20px;

        &:nth-child(2n + 1) {
          padding-right: 10px;
        }

        &:nth-child(2n) {
          padding-left: 10px;
        }
      }
    }
  }

  .instagram-post {
    display: flex;
    position: relative;
    flex-grow: 1;
    justify-content: center;

    &:hover {
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
    }

    @include breakpoint($breakpoint-sm) {
      padding: 0 3px;
      margin: 3px 0;
    }

    @include breakpoint($breakpoint-xs) {
      padding: 0 2px;
      margin: 2px 0;
    }

    &__loading {
      left: 50%;
      top: 50%;
      transform: translate(-70%, -70%);
    }

    &__content {
      display: block;
      color: $charcoal;
      width: 100%;

      &:hover {
        border: none;
      }
    }

    &__image {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        @include size(100%);

        object-fit: cover;
      }
    }

    &__text {
      font-size: 14px;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;
    }
  }

  .event-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__row {
      width: calc(100% + 10px);
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      @include breakpoint($breakpoint-xs) {
        margin: 0;
        width: 100%;
      }
    }

    &__item {
      position: relative;
      height: inherit;
      width: 100%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      padding: 10px;

      @include breakpoint($breakpoint-sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @include breakpoint($breakpoint-xs) {
        flex: 0 0 100%;
        max-width: 100%;
        border-right: none;
        margin-bottom: 20px;
        padding: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .event-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      background: $white;
      border: 1px solid $smoke;
      border-radius: 3px;
      //min-width: 300px;

      &:hover {
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
      }

      &__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: space-between;
        justify-content: space-between;
      }

      &__text-content {
        color: $black;
        display: block;
        padding: 20px;
      }

      &__additional {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        border-top: 1px solid $smoke;
        width: 100%;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.3px;
      }

      &__title {
        font-family: $font-roboto;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      &__date {
        color: $black;
      }

      &__description {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.3px;
      }

      &__book {
        font-weight: 700;
        text-transform: uppercase;
        text-align: right;
      }

      &__coming-soon {
        font-weight: 700;
        color: $concrete;
        text-transform: uppercase;
        text-align: right;
      }
    }
  }
}
