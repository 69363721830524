@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/typography';
@import '~arduino-sass/src/breakpoints';
@import '~arduino-sass/src/utils';
@import 'normalize';
@import 'variables';
@import 'mixins';

html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

* {
  box-sizing: inherit;

  &::before,
  &::after {
    box-sizing: inherit;
  }
}

img,
a {
  border: 0; // fix for IE10
}

img {
  max-width: 100%;
  height: auto;
}

body {
  background: $white;
  color: $black;
  font-size: $default-size;
  line-height: 1.4;
  font-family: $font-sans;
  font-weight: 400;
  height: 100%;
  margin: 0;
  min-height: 100%;
  padding: 0;

  @include breakpoint($breakpoint-xl) {
    font-size: 18px;
  }

  @include breakpoint($breakpoint-sm) {
    font-size: 14px;
  }
}

p {
  margin: 0 0 15px;

  &:last-child:not(:first-child) {
    margin: 0;
  }
}

.disable-select {
  user-select: none;
}

hr {
  background-color: $silver;
  border: none;
  height: 1px;
  padding: 0;
  min-width: 100%;
}

input:not([type='checkbox']):not([type='radio']),
button {
  appearance: none;
  background: transparent;
  box-shadow: none;
}

button:focus {
  outline: none;
}

.center {
  text-align: center;
}

a {
  color: $teal2;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $teal5;
  }
}

a[role='button'] {
  border-bottom: none;
}

.menu-icon {
  width: 18px;
  height: 18px;

  &__block {
    display: none;

    @include breakpoint($breakpoint-md) {
      display: block;
    }
  }
}
