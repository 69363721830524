@import '~arduino-sass/src/breakpoints';
@import '../../../../styles/variables';

.two-columns-content {
  &__content {
    display: flex;
    letter-spacing: 0.3px;
    align-content: stretch;
  }

  &__item {
    hr {
      margin: 100px -85px;

      @include breakpoint($breakpoint-xl) {
        margin: 100px -25px;
        width: calc(100% + 25px * 2);
      }

      @include breakpoint($breakpoint-lg) {
        margin: 100px -15px;
        width: calc(100% + 15px * 2);
      }
    }

    &:last-child {
      hr {
        display: none;
      }
    }
  }

  &__header {
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-bottom: 25px;
    font-weight: 700;
    color: $dust;
  }

  pre {
    overflow: auto;
    white-space: pre-wrap;
    word-break: break-word;
    background-color: #000;
    margin-bottom: 50px;
  }

  &__right {
    width: 320px;
    margin-left: 75px;
    // flex: 0 1 auto;
    border-left: 1px solid $asbestos;

    @include breakpoint($breakpoint-xl) {
      margin-left: 40px;
    }

    @include breakpoint($breakpoint-lg) {
      width: 250px;
    }

    &.no-left-content {
      border-left: none;
      margin-left: 15px;
      width: 600px;
    }
  }

  &__left {
    flex: 1;
  }

  &__image {
    &.type--ImageRecord {
      padding: 40px 0;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 1.8;

    @include breakpoint($breakpoint-xl) {
      font-size: 15px;
      line-height: 1.7;
    }

    .two-columns-content__blockquote & {
      color: $silver;
      font-size: 25px;
      letter-spacing: 0.25px;
      line-height: 40px;
      margin-bottom: 15px;

      p {
        margin: 0;
      }
    }

    .two-columns-content__text-record & {
      ul {
        font-size: 14px;
        padding-inline-start: 20px;
      }
    }

    .not-only-right-content & {
      font-size: 14px;
    }
  }

  &__title {
    font-size: 30px;
    margin-bottom: 20px;

    .two-columns-content__right & {
      font-size: 20px;
    }
  }

  &__text-record {
    .two-columns-content__right & {
      color: $silver;
      height: 100%;
      padding-left: 45px;
    }
  }

  &__feature-record {
    .two-columns-content__right & {
      color: $silver;
      padding-left: 45px;
    }
  }

  &__product-page-record {
    margin-bottom: 30px;

    .two-columns-content__right & {
      color: $silver;
      padding-left: 45px;
    }

    &-link {
      display: block;
      margin-bottom: 10px;
    }

    a {
      color: $teal2;
      font-size: 14px;
      font-weight: 700;
      line-height: 1;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;

      &::after {
        content: '>';
        position: absolute;
        transform: translate(50%, 5px);
      }

      &:hover {
        border-bottom: 1px solid;
      }
    }

    img {
      margin: 15px 0;
    }
  }

  &__feature-title {
    font-size: 20px;
    letter-spacing: 1.5px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  &__feature-description {
    font-size: 14px;
    letter-spacing: 0.26px;
    line-height: 27px;

    .two-columns-content__feature-record & {
      ul {
        font-size: 14px;
        padding-inline-start: 20px;
      }
    }
  }

  &__blockquote {
    margin: 50px 0;

    cite {
      color: $asbestos;
      font-style: normal;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1.5px;
      line-height: 19px;
      text-transform: uppercase;
    }
  }

  &__page-title {
    font-size: 20px;
    letter-spacing: 1.5px;
    line-height: 26px;
    margin-bottom: 15px;
  }

  &__page-description {
    font-size: 14px;
    letter-spacing: 0.26px;
    line-height: 27px;
    margin-bottom: 15px;
  }
}

.link-button {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}
