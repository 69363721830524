@import '~arduino-sass/src/variables';

// Typography
$title-size: 36px;
$title-size-md: 26px;
$title-size-sm: 18px;
$default-size: 20px;
// Header
$header-height: 70px;

// Included fonts
$font-sans: 'Open Sans', sans-serif;
$font-roboto: 'Roboto Mono', monospace;

$breakpoint-xxl: 1400;
$breakpoint-xl: 1200;
$breakpoint-lg: 1024;
$breakpoint-md: 992;
$breakpoint-sm: 768;
$breakpoint-xs: 480;
$breakpoint-xxs: 320;
