@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/typography';
@import '~arduino-sass/src/buttons';
@import '~arduino-sass/src/breakpoints';
@import '../../styles/variables';

.app {
  position: relative;
}

.page-content {
  background: $clouds;
  padding: 75px 0 150px;

  @include breakpoint($breakpoint-sm) {
    padding: 75px 0;
  }

  @include breakpoint($breakpoint-sm, 'min') {
    min-height: 100vh;
  }

  &__wrapper {
    max-width: 980px;
    background: $white;
    padding: 20px 50px;
    border: 1px solid #dae3e3;
    border-radius: 6px;
    margin: 0 auto;
    width: 100%;

    @include breakpoint($breakpoint-lg) {
      max-width: 95%;
      padding: 20px 30px;
    }

    @include breakpoint($breakpoint-sm) {
      padding: 20px 15px;
    }
  }

  &__content {
    padding-bottom: 80px;
  }
}

.main-layout {
  min-height: 100vh;
}

.main-container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 10px;
}

.page-container {
  @extend .main-container;

  background: $white;
  border: 1px solid #dae3e3;
  border-radius: 6px;
  padding: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

.cc-banner {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);

  .cc-link {
    text-decoration: none;
    color: $carrot;
    outline: none;
  }
}
